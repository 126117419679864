import '@/styles/main.css'
import 'flag-icons/css/flag-icons.min.css'
import 'lilasia-icons/dist/style.css'
import 'lilasia-ui/dist/style.css'

import App from '@/App.vue'
import metaPixel from '@/plugins/meta-pixel'
import router from '@/router'
import translator from '@/translator'
import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import googleAuth from 'vue3-google-login'

const app = createApp(App)
app.use(createPinia()).use(router).use(translator)

if ('true' === import.meta.env.VITE_GOOGLE_AUTH_ENABLED) {
  app.use(googleAuth, {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID
  })
}

if (import.meta.env.VITE_APP_ENV === 'production') {
  app.use(createGtm({ id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID }))
  app.use(metaPixel, { pixelId: import.meta.env.VITE_META_PIXEL_ID })

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1
  })
}

app.mount('#app')
