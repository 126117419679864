import { useFetch } from '@/composables/fetch'
import Google from '@/event-tracker/google/Google'
import Meta from '@/event-tracker/meta/Meta'
import useAppStore from '@/stores/app'
import useCartStore from '@/stores/cart'
import useSearchStore from '@/stores/search'
import { useLocalStorage } from '@vueuse/core'
import { useToast } from 'lilasia-ui'
import { ref } from 'vue'

export const useTracker = () => {
  const { fetch } = useFetch()
  const { toastError } = useToast()

  const appStore = useAppStore()
  const cartStore = useCartStore()
  const searchStore = useSearchStore()

  // set ttl to 2 hours
  const TTL = 60 * 60 * 2

  const trackProductView = async (product: Product, userId: number | null = null) => {
    // Wait for 200 ms
    await new Promise((resolve) => setTimeout(resolve, 200))

    // Get current timestamp
    const now = Math.floor(Date.now() / 1000)

    // Check if the item is already in the list
    const index = searchStore.recentlyViewedProduct.findIndex((view) => view.id === product.id)

    // If not found, add it to the list
    if (index === -1) {
      searchStore.recentlyViewedProduct.push(<RecentlyViewedProduct>{
        id: product.id,
        type: 'product',
        userId: userId,
        timestamp: now,
        product: product
      })

      try {
        await fetch('product-view')
          .post({ id: product.id, type: 'product', user_id: userId })
          .json()
      } catch (error) {
        const err = error as FetchError
        toastError(err.message)
      }
    }

    // Remove items that are older than 2 hours
    if (index !== -1 && searchStore.recentlyViewedProduct[index].timestamp < now - TTL) {
      searchStore.recentlyViewedProduct.splice(index, 1)
    }

    trackViewContent(product)
  }

  const trackCategoryView = async (categories: Category[], userId: number | null = null) => {
    // Wait for 200 ms
    await new Promise((resolve) => setTimeout(resolve, 200))

    // Get current timestamp
    const now = Math.floor(Date.now() / 1000)
    const recentlyViewedCategory = useLocalStorage(
      `${appStore.storagePrefix}category_view`,
      [] as RecentlyViewedCategory[]
    )

    const trackCategoryIds = ref<number[]>([])
    categories.forEach(async (category) => {
      // Check if the item is already in the list
      const index = recentlyViewedCategory.value.findIndex(
        (view: RecentlyViewedCategory) => view.id === category.id
      )

      // If not found, add it to the list
      if (index === -1 && category.id) {
        recentlyViewedCategory.value.push(<RecentlyViewedCategory>{
          id: category.id,
          userId: userId,
          timestamp: now,
          category: category
        })

        trackCategoryIds.value.push(category.id)
      }

      // Remove items that are older than 2 hours
      if (index !== -1 && recentlyViewedCategory.value[index].timestamp < now - TTL) {
        recentlyViewedCategory.value.splice(index, 1)
      }
    })

    if (trackCategoryIds.value.length) {
      try {
        await fetch('category-view').post({ categories: trackCategoryIds.value }).json()
      } catch (error) {
        const err = error as FetchError
        toastError(err.message)
      }
    }
  }

  const trackMerchantView = async (merchant: Shop, userId: number | null = null) => {
    // Wait for 200 ms
    await new Promise((resolve) => setTimeout(resolve, 200))

    // Get current timestamp
    const now = Math.floor(Date.now() / 1000)

    // Check if the item is already in the list
    const index = searchStore.recentlyViewedMerchant.findIndex((view) => view.id === merchant.id)

    // If not found, add it to the list
    if (index === -1) {
      searchStore.recentlyViewedMerchant.push(<RecentlyViewedMerchant>{
        id: merchant.id,
        userId: userId,
        timestamp: now,
        shop: merchant
      })

      try {
        await fetch('shop-view').post({ id: merchant.id, user_id: userId }).json()
      } catch (error) {
        const err = error as FetchError
        toastError(err.message)
      }
    }

    // Remove items that are older than 2 hours
    if (index !== -1 && searchStore.recentlyViewedMerchant[index].timestamp < now - TTL) {
      searchStore.recentlyViewedMerchant.splice(index, 1)
    }
  }

  const trackSearchQuery = async (data: SearchQueryTracker) => {
    try {
      await fetch('search-query')
        .post({
          keyword: data.keyword,
          result_count: data.result_count,
          user_id: data.userId
        })
        .json()

      trackSearch(data.keyword)
    } catch (error) {
      const fetchError = error as FetchError
      toastError(fetchError.message)
    }
  }

  const trackAddToCart = async (product: Product, quantity: number = 1, guest = false) => {
    Google.addToCart(product, quantity, guest)

    if (!guest) {
      Meta.addToCart(product, quantity)
    }
  }

  const trackAddToWishlist = async (product: Product) => {
    Google.addToWishlist(product)
    Meta.addToWishlist(product)
  }

  const trackInitiateCheckout = async () => {
    const products = cartStore.cart.items
      ?.filter((item) => item.product !== undefined)
      .map((item) => item.product as Product)

    if (products?.length) {
      Google.beginCheckout(products, cartStore.cart.item_subtotal)
      Meta.beginCheckout(products, cartStore.cart.item_subtotal)
    }
  }

  const trackRemoveFromCart = async (products: Product[], value: number) => {
    Google.removeFromCart(products, value)
  }

  const trackSearch = async (searchTerm: string) => {
    Google.search(searchTerm)
    Meta.search(searchTerm)
  }

  const trackViewContent = async (product: Product) => {
    Google.viewItem(product)
    Meta.viewItem(product)
  }

  const trackSignUp = async () => {
    Google.signUp()
    Meta.signUp()
  }

  const trackPurchase = async (order: Order) => {
    Google.purchase(order)
    Meta.purchase(order)
  }

  const trackContact = async () => {
    Google.trackContact()
  }

  const trackInitiateInquiry = async () => {
    Google.trackInitiateInquiry()
  }

  const trackInquirySubmission = async () => {
    Google.trackInquirySubmission()
  }

  const trackShare = async (product: Product) => {
    Google.trackShare(product)
  }

  return {
    trackAddToCart,
    trackAddToWishlist,
    trackCategoryView,
    trackContact,
    trackInitiateCheckout,
    trackInitiateInquiry,
    trackInquirySubmission,
    trackMerchantView,
    trackProductView,
    trackPurchase,
    trackRemoveFromCart,
    trackSearch,
    trackSearchQuery,
    trackShare,
    trackSignUp,
    trackViewContent
  }
}
